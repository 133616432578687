@import "../variables";

.top-page {
	overflow-x: hidden;

	&.layout-detail {
		display: -webkit-box;
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
	}

	.layout-container {
		-webkit-flex: 1 1 100%;
		-moz-flex: 1 1 100%;
		-ms-flex: 1 1 100%;
		-o-flex: 1 1 100%;
		-khtml-flex: 1 1 100%;
		flex: 1 1 100%;
		max-width: 100%;
		display: flex;

		.page-body {
			-webkit-flex: 1 1 100%;
			-moz-flex: 1 1 100%;
			-ms-flex: 1 1 100%;
			-o-flex: 1 1 100%;
			-khtml-flex: 1 1 100%;
			flex: 1 1 100%;
			max-width: 100%;
			background: url("../../images/layout/background-top.png") no-repeat center center;
			background-size: cover;
		}

		.top-content {
			position: relative;
			height: 100%;
			overflow: hidden;

			.top-banner {
				// position: relative;
				// top: calc(100% - 320px);
				font-size: 80%;
			}

			.banner {
				background-color: rgba(255, 255, 255, 0.65);
				padding-top: 1rem;
				padding-left: 0px;
				width: 70%;
				max-width: 50rem;
				margin: auto;

				.greeting {
					padding-left: 40px;
					width: 300px;
					color: $key-color;
					margin-bottom: .1rem;
					font-size: 1.05rem;
					font-weight: bold;
					border-bottom: 3px solid $key-color;
				}

				.logo {
					display: grid;
					padding: .8rem;
					padding-left: 40px;

					a {
						text-decoration: underline;
						color: inherit;
					}

					img {
						max-height: 3.5rem;
						max-width: 100%;
					}
				}

				.btn-detail {
					text-decoration: none;
					color: inherit;
					border: 1px solid;
					line-height: 1.15;
					font-size: 0.813rem;
					text-align: center;
					padding: 0.375rem 0.625rem 0.375rem 0.375rem;
					position: relative;
					min-width: 21rem;
					height: 3rem;
					border-radius: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.btn-detail:after {
					content: "";
					font-weight: bold;
					position: absolute;
					height: 10px;
					width: 10px;
					background-color: transparent;
					border-style: solid;
					border-width: 1px;
					border-color: black black transparent transparent;
					top: 50%;
					right: 10px;
					margin-top: -5px;
					transform: rotate(45deg);
				}
			}

			.banner-sp {
				display: none;
				order: 4;
				background: white;
				margin-top: 15px;
			}

			.board-projector {
				position: absolute;
				top: 7%;
				display: flex;
				justify-content: center;
				left: 50%;
				max-width: 100%;
				-webkit-transform: translateX(-50%);
				-moz-transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				-o-transform: translateX(-50%);
				transform: translateX(-50%);

				.board-item {
					height: 310px;
					max-height: 33vh;
					width: 500px;
					padding: 0.3rem;

					img {
						width: 100%;
						height: 100%;
					}

					.screen {
						overflow: hidden;
						position: relative;
						height: 100%;
						width: 100%;
						cursor: pointer;

						&:hover .board-pc {
							-webkit-filter: contrast(30%);
							filter: contrast(30%);
						}

						&:hover .text-board-normal {
							-webkit-filter: contrast(30%);
							filter: contrast(30%);
						}

						&:hover .text-board {
							opacity: 1;
						}

						&::after {
							content: '';
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							z-index: 2;
						}

						img {
							position: relative;
							z-index: 1;
						}

						.text-board {
							color: $white;
							text-align: center;
							position: absolute;
							z-index: 3;
							font-weight: bold;
							font-size: 1.5rem;
							left: 50%;
							top: 50%;
							width: 90%;
							opacity: 0;
							-webkit-transform: translate(-50%, -50%);
							-moz-transform: translate(-50%, -50%);
							-ms-transform: translate(-50%, -50%);
							-o-transform: translate(-50%, -50%);
							transform: translate(-50%, -50%);
						}

						.text-board-left {
							-webkit-transform: rotate(-5deg);
							-moz-transform: rotate(-5deg);
							-ms-transform: rotate(-5deg);
							-o-transform: rotate(-5deg);
							transform: rotate(-5deg);
							left: 5%;
							top: 32%;
						}

						.text-board-right {
							-webkit-transform: rotate(4deg);
							-moz-transform: rotate(4deg);
							-ms-transform: rotate(4deg);
							-o-transform: rotate(4deg);
							transform: rotate(4deg);
							left: 3%;
							top: 32%;
						}

						.text-board-normal {
							color: $white;
							text-align: center;
							position: absolute;
							z-index: 2;
							font-weight: bold;
							font-size: 4.5rem;
							left: 50%;
							top: 30%;
							width: 90%;
							opacity: 1;
							line-height: 1;
							-webkit-transform: translate(-50%, -50%);
							-moz-transform: translate(-50%, -50%);
							-ms-transform: translate(-50%, -50%);
							-o-transform: translate(-50%, -50%);
							transform: translate(-50%, -50%);
							text-shadow: 0px 0px 4px #fff;
						}

						.text-board-normal-left {
							-webkit-transform: rotate(-5deg);
							-moz-transform: rotate(-5deg);
							-ms-transform: rotate(-5deg);
							-o-transform: rotate(-5deg);
							transform: rotate(-5deg);
							left: 3%;
							top: 20%;
						}

						.text-board-normal-right {
							-webkit-transform: rotate(4deg);
							-moz-transform: rotate(4deg);
							-ms-transform: rotate(4deg);
							-o-transform: rotate(4deg);
							transform: rotate(4deg);
							left: 3%;
							top: 20%;
						}

						.text-board-normal-center {
							-webkit-transform: unset;
							-moz-transform: unset;
							-ms-transform: unset;
							-o-transform: unset;
							transform: unset;
							left: 3%;
							top: 10%;
						}
					}

					&.board-left {
						position: relative;
						perspective-origin: left;
						perspective: 300px;
					}

					&.board-right {
						position: relative;
						perspective-origin: right;
						perspective: 300px;
					}

					&.board-center {
						position: relative;
						perspective-origin: top;
						perspective: 300px;
					}
				}

				.board-pc.board-pc-bg {
					width: 100%;
					height: 100%;
					border: 7px solid #000000;
					border-radius: 20px;
				}

				.board-left .screen .board-pc-bg {
					transform: rotateY(-22deg) rotateZ(-3deg) scaleY(0.86);
				}

				.board-center .screen .board-pc-bg {
					position: relative;
					transform: rotateX(335deg);
					top: -10px;
				}

				.board-right .screen .board-pc-bg {
					transform: rotateY(22deg) rotateZ(3deg) scaleY(0.86);
				}

				.text-board-mobile {
					display: none;
				}
			}

			.footer {
				// padding: .2rem 1rem;
				// position: relative;
				// top: calc(100% - 340px);
				// color: white;
			}

			.screen-center {
				transform: perspective(50vw) rotateX(-10deg);
			}
		}
	}

	.news-title {
		p {
			width: 400px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			margin-bottom: 5px;
		}
	}

    .set-fc{
        &-board-projector{
            a{
                &:hover{
                    text-decoration: none;
                    .set-fc-box-item{
                        filter: contrast(30%);
                    }
                    .text-board-wrap{
                        opacity: 1;
                        z-index: 3;
                    }
                }
            }
            .set-fc-box-item{
                height: 175px;
                background: #3F49FE;
                outline: 5px solid #3F49FE;
                border: 1px solid $white;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0.8;
                &.set-fc-box-left{
                    background: #3F49FE;
                    outline: 5px solid #3F49FE;
                    border: 1px solid $white;
                }
                &.set-fc-box-center{
                    background: #FB0D4C;
                    outline: 5px solid #FB0D4C;
                    border: 1px solid $white;
                }
                &.set-fc-box-right{
                    background: #2ED100;
                    outline: 5px solid #2ED100;
                    border: 1px solid $white;
                }
                h3{
                    font-size: 42px;
                    font-weight: bold;
                    color: #ffffff;
                    text-align: center;
                }
                &:hover{
                    z-index: 1;
                    
                }
            }

            &-item{
                .text-board-wrap{
                    opacity: 0;
                    font-size: 18px;
                    font-weight: 400;
                    color: #ffffff;
                    position: absolute;
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .text-board-mobile{
                    opacity: 0;
                    position: absolute;
                }
            }
        }
        &-top-banner{
            top: auto;
        }
        &-btn-detail{
            text-decoration: underline;
            color: inherit;
            line-height: 1.15;
            text-align: center;
            position: relative;
            margin-right: 20px;
            font-size: 16px;
            font-weight: 400;
            &:after {
                content: "";
                font-weight: bold;
                position: absolute;
                height: 10px;
                width: 10px;
                background-color: transparent;
                border-style: solid;
                border-width: 1px;
                border-color: black black transparent transparent;
                top: 50%;
                right: -15px;
                margin-top: -5px;
                transform: rotate(45deg);
            }
        }

        &-text-border-mobile{
            p{
                color: #fff;
                margin-top: 0.5rem;
                margin-left: auto;
                margin-right: auto;
                font-size: 14px;
            }
        }

        &-footer{
            position: fixed;
            padding: 10px;
            bottom: 0;
            width: 100%;
            background-color: rgba(000, 000, 000, 0.2);
            .footer-menu{
                .menu-item{
                    .menu-link{
                        color: #fff;
                        font-size: 0.6rem;
                        font-weight: 400;
                        text-decoration: none;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }
            .footer-powered{
                span{
                    color: #fff;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }

	.section-news{
		 /* お知らせ・ニュースティッカー */
		#index_news_mobile { 
			display:none; 
		}
		#index_news { 
			height:65px; 
			line-height:65px; 
			overflow:hidden; 
			position:relative;
			position:relative; 
		}
		#index_news ol { margin:0; padding:0; position:absolute; }
		#index_news ol li { margin:0; padding:0; height:65px; font-size:14px; overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
		#index_news ol li a { 
			display:inline-block; 
			padding:0; 
			text-decoration:underline; 
			white-space:nowrap; 
			overflow:hidden; 
			text-overflow:ellipsis; 
			color: #fff !important;
			padding-right: 3rem;
			&:hover{
				text-decoration: none !important;
			}
			@media screen and (max-width: 480px) {
				&.detail{
					display:none; 
				}
			}
		}
		#index_news .entry-date { 
			display:inline-block; 
			margin:0 18px 0 0; 
			font-size:12px; 
			color:#fff; 
			text-decoration:none !important; 
		}
		.archive_link { 
			margin:0; 
			text-align:right; 
			position:absolute; 
			top:0; 
			right:0;
			&.pc{
				@media screen and (max-width: 768px) {
					display: none;
				}
			}
			&.sp{
				display: none;
				@media screen and (max-width: 768px) {
					display: block;
					position: relative;
					margin: 10px;
				}
			}
		}
		.archive_link a {
			text-decoration:underline; 
			color: #fff !important;
			border: 1px solid;
			padding: 10px;
			&:hover{
				text-decoration: none !important;
			}
		}
	}

	.set-slideshow-v2{
		.btn {
			display: inline-block;
			padding: 13px 20px;
			color: #fff;
			text-decoration: none;
			position: relative;
			background: transparent; 
			border: 1px solid #e1e1e1;
			font: 12px/1.2 "Oswald", sans-serif;
			letter-spacing: 0.4em;
			text-align: center;
			text-indent: 2px;
			text-transform: uppercase;
			transition: color 0.1s linear 0.05s;
  
			&::before {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 1px;
				background: #e1e1e1;
				z-index: 1;
				opacity: 0;
				transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0.2s;
			}
  
			&::after {
				transition:border 0.1s linear 0.05s;
			}
  
			.btn-inner {
				position: relative;
				z-index: 2;
			}
  
			&:hover {
				color: #373737;
				transition: color 0.1s linear 0s;
		
				&::before {
					top: 0;
					height: 100%;
					opacity: 1;
					transition: height 0.2s ease, top 0.2s ease, opacity 0s linear 0s;
				}
			
				&::after {
					border-color: #373737;
					transition:border 0.1s linear 0s;
				}
			}
  		}
		.slideshow { 
			overflow: hidden;
			position: relative;
			width: 100%;
			height: 100vh;
			z-index: 1;
			@media screen and (max-width: 768px) {
				height: 50vh;
			}
  
			.slideshow-inner { 
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
  
			.slides { 
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
			}
  
			.slide { 
				display: none;
				overflow: hidden;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				opacity: 0;
				transition: opacity 0.3s ease;
  
				&.is-active {
					display: block;
				}
  
				&.is-loaded{
					opacity: 1;
				}
  
				.caption { 
					padding: 0 100px;
				}
  
	  .image-container { 
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-position: center;
		z-index: 1;
		background-size: cover;
		image-rendering: optimizeQuality;
  
		&::before { 
		  content: "";
		  display: block;
		  position: absolute;
		  top: 0;
		  left: 0;
		  width: 100%;
		  height: 100%;
		  background: rgba(0,0,0,0.5);
		}
	  }
  
	  .image {
		width: 100%;
		width: 100%;
		object-fit: cover;
		height: 100%;
	  }
  
	  &-content {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		color: #fff;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	  }
  
	  .title { 
		margin: 0 auto 15px;
		max-width: 1000px;
		font: 300 50px/1.2 "Oswald", sans-serif;
		letter-spacing: 0.35em;
		text-transform: uppercase;
	  }
  
	  .text {
		margin: 0 auto;
		max-width: 1000px;
		font-size: 18px;
		line-height: 1.4;
	  }
  
	  .btn {
		margin: 15px 0 0;
		border-color: #fff;
  
		&::before {
		  background:#fff
		}
	  }
	}
  
	.pagination { 
	  position: absolute;
	  bottom: 35px;
	  left: 0;
	  width: 100%;
	  height: 12px;
	  cursor: default;
	  z-index: 2;
	  text-align: center;
  
	  .item { 
		display: inline-block;
		padding: 15px 5px;
		position: relative;
		width: 46px;
		height: 32px;
		cursor: pointer;
		text-indent: -999em;
		z-index: 1;
  
		+ .page {
		  margin-left: -2px;
		}
  
		&::before {
		  content: "";
		  display: block;
		  position: absolute;
		  top: 15px;
		  left: 5px;
		  width: 36px;
		  height: 2px;
		  background: rgba(255, 255, 255, 0.5);
		  transition: background 0.2s ease;
		}
  
		&::after {
		  width: 0;
		  background: #fff;
		  z-index: 2;
		  transition: width 0.2s ease;
		}
  
		&:hover::before,
		&.is-active::before {
		  background-color: #fff;
		}
	  }
	}
  
	.arrows {
	  .arrow {
		margin: -33px 0 0;padding: 20px;position: absolute;top: 50%;cursor: pointer;z-index: 3;
	  }
  
	  .prev {
		left: 30px;
  
		&:hover .svg {
		  left: -10px;
		}
	  }
  
	  .next {
		right: 30px;
  
		&:hover .svg {
		  left: 10px;
		}
	  }
  
	  .svg {
		position: relative;
		left: 0;
		width: 14px;
		height: 26px;
		fill: #fff;
		transition: left 0.2s ease;
	  }
	}
  }
	}

	.set-fc-animation{
		/*========= レイアウトのためのCSS ===============*/
		&.flex{
			display:flex;
			flex-wrap: wrap;
  		}
	
		/*==================================================
		ふわっ
		===================================*/
	
		&.fadeUp {
			animation-name:fadeUpAnime;
			animation-duration:1.5s;
			animation-fill-mode:forwards;
			opacity: 0;
  		}
  		
		@keyframes fadeUpAnime{
			from {
				opacity: 0;
				transform: translateY(100px);
			}
		
			to {
				opacity: 1;
				transform: translateY(0);
			}
		}
	
		/* アニメーションスタートの遅延時間を決めるCSS*/
		
		&.delay-time02{
			animation-delay: 0.2s;
		}

		&.delay-time03{
			animation-delay: 0.3s;
		}
	
		&.delay-time04{
			animation-delay: 0.4s;
		}

		&.delay-time05{
			animation-delay: 0.5s;
		}

		&.delay-time06{
			animation-delay: 0.6s;
		}
	
		&.delay-time07{
			animation-delay: 0.7s;
		}

		&.delay-time08{
			animation-delay: 0.8s;
		}

		&.delay-time09{
			animation-delay: 0.9s;
		}
	
		&.delay-time10{
			animation-delay: 1s;
		}

		&.delay-time11{
			animation-delay: 1.1s;
		}

		&.delay-time12{
			animation-delay: 1.2s;
		}

		&.delay-time13{
			animation-delay: 1.3s;
		}

		&.delay-time14{
			animation-delay: 1.4s;
		}

		&.delay-time15{
			animation-delay: 1.5s;
		}
	}

}

@media screen and (min-width: 768px) and (max-width: 991px) {
	.top-page {
		.layout-container {
			.top-content {
				.board-projector {
					.board-item {
						.screen {
							.text-board {
								font-size: 1.2rem;
							}

							.text-board-left {
								left: 6%;
								top: 41%;
								-webkit-transform: rotate(-9deg);
								-moz-transform: rotate(-9deg);
								-ms-transform: rotate(-9deg);
								-o-transform: rotate(-9deg);
								transform: rotate(-9deg);
							}

							.text-board-right {
								left: 2%;
								top: 37%;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-height: 700px) and (min-width: 768px) {
	.top-page {
		.layout-container {
			min-height: 750px;

			.top-content {
				.board-projector {
					.board-item {
						height: 295px;
						max-height: 1000px;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 480px) {
	.top-page{
		.set-fc{
			&-footer{
				.footer-menu{
					.menu-item{
						.menu-link{
							font-size: 0.5rem;
						}
					}
				}
			}
		}
	}
	
}

@media screen and (max-width: 370px) {
	.news-title {
		p {
			width: 200px !important;
		}
	}

	.btn-detail {
		min-width: 15rem !important;
	}

	.carousel-inner {
		max-height: 240px;
	}
}

@media screen and (max-width: 768px), screen and (max-height: 480px) {
	.top-page {
		.layout-container {
			.page-body {
				background: transparent;
			}

			.top-content {
				height: auto;
				overflow: initial;
				background-color: $white;
				color: $black;
				display: flex;
				-webkit-flex-direction: column;
				-moz-flex-direction: column;
				-ms-flex-direction: column;
				-o-flex-direction: column;
				-khtml-flex-direction: column;
				flex-direction: column;

				.top-banner {
					width: 100%;
					top: auto;
					padding: 2rem .5rem;
					min-height: 250px;
				}

				.banner {
					width: 100%;
					padding: .8rem;
					color: $black;

					.greeting {
						font-size: 1.125rem;
					}

					.logo {}
				}

				.banner-sp {
					display: block;
				}

				.banner-pc {
					display: none;
				}

				.board-projector {
					position: relative;
					top: auto;
					left: auto;
					display: flex;
					flex-direction: column;
					-webkit-flex: 1 1 auto;
					-moz-flex: 1 1 auto;
					-ms-flex: 1 1 auto;
					-o-flex: 1 1 auto;
					-khtml-flex: 1 1 auto;
					flex: 1 1 auto;
					align-items: center;
					justify-content: center;
					-webkit-transform: none;
					-moz-transform: none;
					-ms-transform: none;
					-o-transform: none;
					transform: none;

					.board-item {
						height: auto;
						width: auto;
						max-width: 500px !important;
						padding: 0 .5rem;
						margin: auto;
						max-height: 100000px;

						&.board-center {
							order: 2
						}

						&.board-left {
							order: 1;
						}

						&.board-right {
							order: 3;
						}

						.board-help {
							color: $white;
							//text-align: center;
							margin-top: .5rem;
							margin-left: auto;
							margin-right: auto;
							font-size: 14px;

							br {
								display: none;
							}
						}

						.screen {
							margin: 1.7rem auto 0 auto;
							height: 110px;
							max-width: 400px;
							-webkit-transform: none !important;
							-moz-transform: none !important;
							-ms-transform: none !important;
							-o-transform: none !important;
							transform: none !important;

							.board-pc {
								display: none;
							}

							.text-board {
								display: none;
							}

							.text-board-mobile {
								display: block;
								color: $white;
								text-align: center;
								position: absolute;
								z-index: 3;
								line-height: 1;
								font-weight: normal;
								font-size: 2.5rem;
								left: 50%;
								top: 50%;
								width: 90%;
								text-shadow: 0px 0px 4px #fff;
								-webkit-transform: translate(-50%, -50%);
								-moz-transform: translate(-50%, -50%);
								-ms-transform: translate(-50%, -50%);
								-o-transform: translate(-50%, -50%);
								transform: translate(-50%, -50%);
							}
						}

						.text-board-normal {
							display: none;
						}

						.screen {
							display: block;
							margin: 1.7rem auto 0 auto;
							height: 110px;
							width: 80vw;
							transform: none !important;
							border: 2px solid #ffffff;
							border-radius: 6px;
						}
					}
				}

				.footer {
					// position: relative;
					top: auto;
					margin-top: 2rem;
					padding-left: 0;
					padding-right: 0;
					background-color: $key-color;
				}
			}
		}

		.news-title {
			p {
				width: 300px;
			}
		}
        .set-fc{
            &-board-projector{
                .board-pc {
                    display: none;
                }
            }
        }

        .set-fc{
            &-board-projector{
                h3{
                    font-size: 32px;
                }
            }
        }
	}
}